<template>
  <div class="page__wrapper">
    <!-- 整表信息 -->
    <div class="top-desc">
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerCode') }}{{ headInfo.dealer && headInfo.dealer.code }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ $t('dealer_finance.operatingData.reporter') }}{{ headInfo.compiler }}
        </el-col>
      </el-row>
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerName') }}{{ headInfo.dealer && headInfo.dealer.name }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ period }}
        </el-col>
      </el-row>
    </div>
    <ag-grid
      ref="balanceGrid"
      style="width:100%;height:89%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    />
  </div>
</template>

<script>
const BASEURL = {
  list: '/jlr/tt/balanceSheet/wrapper'
}

export default {
  name: 'Balance',
  props: {
    detailData: { type: Object },
    headInfo: { type: Object }
  },
  data () {
    return {
      gridOptions: {},
      frameworkComponents: {},
      isShowDetailDialog: false,
      tableData: [
        {
          accountCategory: '资产'
        },
        {
          accountCategory: '流动资产',
          firstLevelAccount: '货币资金',
          code: 'code1001'
        },
        {
          firstLevelAccount: '交易性金融资产',
          code: 'code1101'
        },
        {
          firstLevelAccount: '应收票据',
          code: 'code1121'
        },
        {
          firstLevelAccount: '应收账款',
          code: 'code1122'
        },
        {
          firstLevelAccount: '预付款项',
          code: 'code1123'
        },
        {
          firstLevelAccount: '其他应收款',
          code: 'code1231'
        },
        {
          firstLevelAccount: '存货',
          secondLevelAccount: '整车',
          thirdLevelAccount: '新车',
          code: 'code14060101'
        },
        {
          thirdLevelAccount: '二手车',
          code: 'code14060102'
        },
        {
          thirdLevelAccount: '试乘试驾车',
          code: 'code14060103'
        },
        {
          secondLevelAccount: '配件',
          code: 'code140602'
        },
        {
          secondLevelAccount: '精品',
          code: 'code140603'
        },
        {
          secondLevelAccount: '其他',
          code: 'code140699'
        },
        {
          secondLevelAccount: '小计',
          code: 'codezsum1'
        },
        {
          firstLevelAccount: '持有待售资产',
          code: 'code1481'
        },
        {
          firstLevelAccount: '一年内到期的非流动资产',
          code: 'code1462'
        },
        {
          firstLevelAccount: '在制品',
          code: 'code1410'
        },
        {
          firstLevelAccount: '其他流动资产',
          code: 'code1399'
        },
        {
          firstLevelAccount: '流动资产合计',
          code: 'codezsum2'
        },
        {
          accountCategory: '非流动资产',
          firstLevelAccount: '可供出售金融资产',
          code: 'code1523'
        },
        {
          firstLevelAccount: '持有至到期投资',
          code: 'code1521'
        },
        {
          firstLevelAccount: '长期应收款',
          code: 'code1531'
        },
        {
          firstLevelAccount: '长期股权投资',
          code: 'code1524'
        },
        {
          firstLevelAccount: '固定资产',
          secondLevelAccount: '原值',
          code: 'code1601'
        },
        {
          secondLevelAccount: '累计折旧',
          code: 'code1602'
        },
        {
          secondLevelAccount: '小计',
          code: 'codezsum3'
        },
        {
          firstLevelAccount: '在建工程',
          code: 'code1604'
        },
        {
          firstLevelAccount: '无形资产',
          secondLevelAccount: '原值',
          code: 'code1701'
        },
        {
          secondLevelAccount: '累计摊销',
          code: 'code1702'
        },
        {
          secondLevelAccount: '小计',
          code: 'codezsum4'
        },
        {
          firstLevelAccount: '长期待摊费用',
          code: 'code1801'
        },
        {
          firstLevelAccount: '递延所得税资产',
          code: 'code1811'
        },
        {
          firstLevelAccount: '其他非流动资产',
          code: 'code1999'
        },
        {
          firstLevelAccount: '非流动资产合计',
          code: 'codezsum5'
        },
        {
          accountCategory: '资产总计',
          code: 'codezsum6'
        },
        {
          accountCategory: '负债及所有者权益'
        },
        {
          accountCategory: '流动负债',
          firstLevelAccount: '短期借款',
          code: 'code2001'
        },
        {
          firstLevelAccount: '交易性金融负债',
          code: 'code2101'
        },
        {
          firstLevelAccount: '应付票据',
          code: 'code2201'
        },
        {
          firstLevelAccount: '应付账款',
          code: 'code2202'
        },
        {
          firstLevelAccount: '预收款项',
          code: 'code2203'
        },
        {
          firstLevelAccount: '应付职工薪酬',
          code: 'code2211'
        },
        {
          firstLevelAccount: '应交税费',
          code: 'code2221'
        },
        {
          firstLevelAccount: '应付利息',
          code: 'code2231'
        },
        {
          firstLevelAccount: '其他应付款',
          code: 'code2241'
        },
        {
          firstLevelAccount: '持有待售负债',
          code: 'code2245'
        },
        {
          firstLevelAccount: '一年内到期的非流动负债',
          code: 'code2402'
        },
        {
          firstLevelAccount: '其他流动负债',
          code: 'code2499'
        },
        {
          firstLevelAccount: '流动负债合计',
          code: 'codezsum7'
        },
        {
          accountCategory: '非流动负债',
          firstLevelAccount: '长期借款',
          code: 'code2501'
        },
        {
          firstLevelAccount: '应付债券',
          code: 'code2502'
        },
        {
          firstLevelAccount: '长期应付款',
          code: 'code2701'
        },
        {
          firstLevelAccount: '预计负债',
          code: 'code2801'
        },
        {
          firstLevelAccount: '递延收益',
          code: 'code2401'
        },
        {
          firstLevelAccount: '递延所得税负债',
          code: 'code2901'
        },
        {
          firstLevelAccount: '其他非流动负债',
          code: 'code2999'
        },
        {
          firstLevelAccount: '非流动负债合计',
          code: 'codezsum8'
        },
        {
          accountCategory: '负债总计',
          code: 'codezsum9'
        },
        {
          accountCategory: '所有者权益',
          firstLevelAccount: '实收资本（股本）',
          code: 'code4001'
        },
        {
          firstLevelAccount: '资本公积',
          code: 'code4002'
        },
        {
          firstLevelAccount: '其他综合收益',
          code: 'code4003'
        },
        {
          firstLevelAccount: '盈余公积',
          code: 'code4101'
        },
        {
          firstLevelAccount: '未分配利润',
          code: 'code4104'
        },
        {
          firstLevelAccount: '少数股东权益*',
          code: 'code3005'
        },
        {
          firstLevelAccount: '所有者权益合计',
          code: 'codezsum10'
        },
        {
          accountCategory: '负债及所有者权益总计',
          code: 'codezsum11'
        }
      ]
    }
  },
  computed: {
    period () {
      return this.headInfo.period ? this.$moment(this.headInfo.period, 'YYYYMM').format('YYYY年MM月') : ''
    },
    openingBalanceDate () {
      return this.headInfo.period ? this.$moment(this.headInfo.period, 'YYYYMM').subtract(1, 'months').endOf('month').format('YYYY年MM月DD日') : ''
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.balance.accountCategory'),
          field: 'accountCategory',
          cellStyle: params => {
            const arr = ['资产', '负债及所有者权益']
            const isA = arr.indexOf(params.data.accountCategory) > -1
            const arr2 = ['流动资产', '非流动资产', '流动负债', '非流动负债', '所有者权益']
            const isB = arr2.indexOf(params.data.accountCategory) > -1
            const arr3 = ['负债及所有者权益总计', '负债总计', '资产总计']
            const isC = arr3.indexOf(params.data.accountCategory) > -1
            const style1 = { backgroundColor: '#ebebeb' }
            const style2 = { backgroundColor: 'white' }
            const style3 = { fontWeight: 600, textAlign: 'right', fontSize: '14px' }
            return isA ? style1 : (isB ? style2 : (isC ? style3 : {}))
          },
          colSpan: params => {
            const arr = ['资产', '负债及所有者权益']
            const arr2 = ['负债总计', '资产总计', '负债及所有者权益总计']
            return arr.indexOf(params.data.accountCategory) > -1 ? 10 : (arr2.indexOf(params.data.accountCategory) > -1 ? 4 : 1)
          },
          rowSpan: params => {
            const isA = (params.data.accountCategory === '流动资产')
            const isB = (params.data.accountCategory === '非流动资产')
            const isC = (params.data.accountCategory === '流动负债')
            const isD = (params.data.accountCategory === '非流动负债')
            const isE = (params.data.accountCategory === '所有者权益')
            return isA ? 17 : (isB ? 14 : (isC ? 12 : (isD ? 7 : (isE ? 6 : 1))))
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.balance.firstLevelAccount'),
          field: 'firstLevelAccount',
          cellStyle: params => {
            const isA = params.data.firstLevelAccount === '存货'
            const arr = ['流动资产合计', '非流动资产合计', '流动负债合计', '非流动负债合计', '所有者权益合计']
            const isB = arr.indexOf(params.data.firstLevelAccount) > -1
            return isA ? { backgroundColor: 'white' } : (isB ? { fontWeight: 600, textAlign: 'right' } : {})
          },
          rowSpan: params => {
            return params.data.firstLevelAccount === '存货' ? 6 : 1
          },
          colSpan: params => {
            const arr = [
              '货币资金',
              '交易性金融资产',
              '应收票据',
              '应收账款',
              '预付款项',
              '其他应收款',
              '持有待售资产',
              '一年内到期的非流动资产',
              '在制品',
              '其他流动资产',
              '可供出售金融资产',
              '持有至到期投资',
              '长期应收款',
              '长期股权投资',
              '在建工程',
              '长期待摊费用',
              '递延所得税资产',
              '其他非流动资产',
              '流动资产合计',
              '非流动资产合计',
              '短期借款',
              '交易性金融负债',
              '应付票据',
              '应付账款',
              '预收款项',
              '应付职工薪酬',
              '应交税费',
              '应付利息',
              '其他应付款',
              '持有待售负债',
              '一年内到期的非流动负债',
              '其他流动负债',
              '流动负债合计',
              '长期借款',
              '应付债券',
              '长期应付款',
              '预计负债',
              '递延收益',
              '递延所得税负债',
              '其他非流动负债',
              '非流动负债合计',
              '实收资本（股本）',
              '资本公积',
              '其他综合收益',
              '盈余公积',
              '未分配利润',
              '少数股东权益*',
              '所有者权益合计'
            ]
            return arr.indexOf(params.data.firstLevelAccount) > -1 ? 3 : 1
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.balance.secondLevelAccount'),
          field: 'secondLevelAccount',
          rowSpan: params => {
            return params.data.secondLevelAccount === '整车' ? 2 : 1
          },
          colSpan: params => {
            return params.data.secondLevelAccount === '小计' ? 2 : 1
          },
          cellStyle: params => {
            const isA = params.data.secondLevelAccount === '整车'
            const isB = params.data.secondLevelAccount === '小计'
            return isA ? { backgroundColor: 'white' } : (isB ? { fontWeight: 600, textAlign: 'right' } : {})
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.balance.thirdLevelAccount'),
          field: 'thirdLevelAccount',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.balance.openinBalance'),
          children: [
            {
              headerName: this.openingBalanceDate,
              field: 'openingBalance',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 140
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.balance.benchmarkReport'),
          children: [
            {
              headerName: this.period,
              field: 'beginBalance',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 130
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.balance.adjustment'),
          children: [
            {
              headerName: this.$t('dealer_finance.balance.lend'),
              field: 'debit',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            },
            {
              headerName: this.$t('dealer_finance.balance.loan'),
              field: 'credit',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.balance.adjustmentReport'),
          children: [
            {
              headerName: this.period,
              field: 'endBalance',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.balance.remark'),
          field: 'remark',
          minWidth: 480
        }
      ]
    }
  },
  watch: {
    headInfo (info) {
      this.$refs.balanceGrid.redrawGrid(this.columnDefs)
    }
  },
  beforeMount () {
    this.gridOptions.suppressRowTransform = true
    this.gridOptions.getRowStyle = params => {
      const arr = [19, 49, 58]
      if (arr.indexOf(params.node.rowIndex) > -1) {
        return { borderTopColor: '#999' }
      }
    }
  },
  methods: {
    handleDataSearch () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] })
        .then(resp => {
          const respData = resp.data
          let result
          const rs = this.tableData.map(ele => {
            result = {}
            Object.keys(respData).forEach(key => {
              if (key === ele.code) { result = Object.assign({}, ele, respData[key]) }
            })
            return Object.keys(result).length ? result : ele
          })
          this.$set(this, 'tableData', rs)
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
    padding-right: 8px;
    .top-desc {
      font-size: 12px;
      padding: 4px 4px;
    }
  }
</style>
