<template>
  <div class="page__wrapper">
    <!-- 整表信息 -->
    <div class="top-desc">
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerCode') }}{{ headInfo.dealer && headInfo.dealer.code }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ $t('dealer_finance.operatingData.reporter') }}{{ headInfo.compiler }}
        </el-col>
      </el-row>
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerName') }}{{ headInfo.dealer && headInfo.dealer.name }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ headInfo.period ? $moment(headInfo.period, 'YYYYMM').format('YYYY年MM月') : '' }}
        </el-col>
      </el-row>
    </div>
    <!-- 主列表 -->
    <ag-grid
      ref="ajustingEntriesGrid"
      edit-type="fullRow"
      style="width:100%;height:89%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :grid-options="gridOptions"
      :framework-components="frameworkComponents"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-row>
            <el-col
              :span="12"
              class="tip"
            >
              {{ isEqual ? '' : `提示：借贷未平（借方：${sumDebit}，贷方：${sumCredit}）` }}
            </el-col>
            <el-col
              v-show="editable"
              :span="12"
            >
              <el-button
                type="primary"
                @click="handleAddClick"
              >
                {{ $t('operation.create') }}
              </el-button>
              <!-- <el-button
                v-show="editable"
                @click="handleDataSearch"
              >
                {{ $t('operation.search') }}
              </el-button> -->
            </el-col>
          </el-row>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/jlr/tt/adjustedValue/list',
  save: '/jlr/tt/adjustedValue/save',
  update: '/jlr/tt/adjustedValue/update',
  delete: '/jlr/tt/adjustedValue/delete',
  subject: '/jlr/mt/subjectCode/list'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleDeleteClick', 'handleSaveClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-circle-check" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.cancel')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-refresh-left" @click="handleDataSearch" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete" @click="handleDeleteClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'AjustingEntries',
  props: {
    editable: { type: Boolean, default: true },
    headInfo: { type: Object },
    detailData: { type: Object }
  },
  data () {
    return {
      searchModel: {},
      gridOptions: {},
      maxSort: 0,
      lastNo: 0,
      lastType: null,
      tableData: [],
      subjectList: [],
      sumCredit: 0,
      sumDebit: 0,
      isEqual: false,
      frameworkComponents: {},
      isShowDetailDialog: false,
      subjectLoadingFlag: false
    }
  },
  computed: {
    searchFormField () {
      return [
        { slotName: 'tableOperation', col: { xs: 24, sm: 24, md: 24 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.adjustingEntries.order'),
          field: 'no',
          editable: this.editable,
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.adjustingEntries.lendLoan'),
          field: 'type',
          valueGetter: params => {
            const isDebit = (params.data.debit !== undefined && params.data.debit !== 0)
            const isCredit = (params.data.credit !== undefined && params.data.credit !== 0)
            return isDebit ? 'tt_adjusted_value_type_1' : (isCredit ? 'tt_adjusted_value_type_2' : null)
          },
          valueFormatter: params => this.$getDictLabel({ type: 'tt_adjusted_value_type', value: params.value }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.adjustingEntries.account'),
          field: 'subjectObj',
          editable: this.editable,
          type: 'Select',
          cellEditorParams: {
            searchable: true,
            componentProp: {
              remoteMethod: this.getSubjectList,
              loading: this.subjectLoadingFlag,
              optionList: this.subjectList,
              valueKey: 'subjectCode'
            },
            event: {
              focus: () => this.getSubjectList()
            },
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          valueFormatter: params => {
            return params.data.subjectObj && params.data.subjectObj.subject
          },
          minWidth: 460
        },
        {
          headerName: this.$t('dealer_finance.adjustingEntries.adjustmentAmount'),
          children: [
            {
              headerName: this.$t('dealer_finance.adjustingEntries.debtor'),
              field: 'debit',
              editable: this.editable,
              valueParser: params => {
                return Number(this.$utils.decimalFormat(params.newValue, 2))
              },
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            },
            {
              headerName: this.$t('dealer_finance.adjustingEntries.creditor'),
              field: 'credit',
              editable: this.editable,
              valueParser: params => {
                return Number(this.$utils.decimalFormat(params.newValue, 2))
              },
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.adjustingEntries.adjustmentRemark'),
          field: 'remark',
          editable: this.editable,
          minWidth: 630
        },
        {
          headerName: this.$t('dealer_finance.adjustingEntries.updateBy'),
          field: 'updateBy.name',
          minWidth: 100
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 100,
          pinned: 'right',
          hide: !this.editable,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  watch: {
    editable (val) {
      this.$nextTick(() => {
        this.$refs.ajustingEntriesGrid.redrawGrid(this.columnDefs)
      })
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      params.page = { orderBy: 'sort' }
      params.filter = [{ left: 'head.id', operate: '=', right: this.detailData.id }]
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.sumCredit = 0
          this.sumDebit = 0
          this.tableData = respData.list.map(ele => {
            this.sumCredit += ele.credit
            this.sumDebit += ele.debit
            if (ele.sort && ele.sort > this.maxSort) this.maxSort = ele.sort
            if (ele.no) this.lastNo = ele.no
            if (ele.type) this.lastType = ele.type
            ele.subjectObj = { subject: ele.subject, subjectCode: ele.subjectCode }
            return ele
          })
          this.sumCredit = this.$utils.decimalFormat(this.sumCredit, 2)
          this.sumDebit = this.$utils.decimalFormat(this.sumDebit, 2)
          this.isEqual = this.sumCredit === this.sumDebit
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.tableData.push({ sort: this.tableData.length })
      this.$nextTick(() => {
        this.$refs.ajustingEntriesGrid.focusOnCell(this.tableData.length - 1, 'subjectObj')
      })
    },
    handleDeleteClick (row) {
      if (!row.id) return this.handleDataSearch()
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleSaveClick (row) {
      this.$refs.ajustingEntriesGrid.validate(row, status => {
        if (status) {
          // 借贷只能填一者
          if (row.data.debit && row.data.credit) {
            this.$refs.ajustingEntriesGrid.focusOnCell(row.rowIndex, 'debit')
            return this.$message({ type: 'warning', message: this.$t('dealer_finance.adjustingEntries.validateTip') })
          }
          // 提交数据处理
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitData = this.$_.cloneDeep(row.data)
          if (submitData.subjectObj) {
            submitData.subject = submitData.subjectObj.subject
            submitData.subjectCode = submitData.subjectObj.subjectCode
          }
          // sort
          if (!row.data.id) submitData.sort = this.maxSort + 1
          // type
          const isDebit = (row.data.debit !== undefined && row.data.debit !== 0)
          const isCredit = (row.data.credit !== undefined && row.data.credit !== 0)
          submitData.type = isDebit ? 'tt_adjusted_value_type_1' : (isCredit ? 'tt_adjusted_value_type_2' : null)
          // no
          if (!submitData.no) {
            for (let i = 0; i < row.rowIndex; i++) {
              if (this.tableData[i].no) this.lastNo = this.tableData[i].no
            }
            if (row.rowIndex === 0) {
              submitData.no = 1
            } else if (this.tableData[row.rowIndex - 1].type === 'tt_adjusted_value_type_2' && submitData.type === 'tt_adjusted_value_type_1') {
              submitData.no = this.lastNo + 1
            } else {
              submitData.no = null
            }
          }
          submitData.head = this.headInfo
          this.$axios
            .post(row.data.id ? BASEURL.update : BASEURL.save, submitData)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    getSubjectList (name) {
      let filter = [{ left: 'isFilter', operate: '=', right: 'Y' }]
      if (name) {
        filter.push({ left: 'subject', operate: 'like', right: name })
      }
      this.subjectLoadingFlag = true
      this.$axios
        .post(BASEURL.subject, { filter: filter })
        .then(resp => {
          const respData = resp.data
          this.subjectList = respData.list.map(item => {
            return { key: item.subjectCode, value: item, label: item.subject }
          })
        })
        .finally(() => {
          this.subjectLoadingFlag = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
    padding-right: 8px;
    .top-desc {
      font-size: 12px;
      padding: 4px 4px;
    }
    .tip {
      font-size: 12px;
      font-weight: 600;
      color: red;
      text-align: left;
      padding-left: 2px;
      border: 1px solid transparent;
    }
  }
</style>
